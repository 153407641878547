<template>
    <div :class="[var_view || player_only ? 'no_pad_event': 'pad_event']" :style="bodyStyle">
        <!-- <div style="text-align: center; cursor: pointer; position: absolute; top: 60px; right: 10px; z-index: 100; width: 60px; heihgt: 30px; border-radius: 4px; background-color: white;">
            <b-icon icon="badge-cc"></b-icon>
        </div> -->
        <div class="liha" style="overflow: scroll">
            <div :class="{'no-fade-media-control' : var_view}" id="player" :style="player_style">
                <div v-if="!mobile && !hideArrow && !player_only" @mouseenter="togglerHover = true" @mouseleave="togglerHover = false" @click="sideBarOpen = !sideBarOpen; onResize(); togglerHover = false" class="toggler">
                    <transition name="fade">
                        <span v-if="togglerHover" class="togglerText">{{!sideBarOpen ? languages[currentLang]['open_sidebar'] : languages[currentLang]['close_sidebar']}}</span>
                    </transition>
                    <b-icon v-if="sideBarOpen" icon="arrow-bar-right" style="color: white"></b-icon>
                    <b-icon v-else icon="arrow-bar-left" style="color: white"></b-icon>
                </div>
                <VueClappr
                    :var_view="var_view"
                    el="player"
                    ref="clappr"
                    :source="source"
                    :record_source="record_source"
                    :useDvrControls="useDvrControls"
                    :options="options"
                    :key="keys"
                    v-if="!upcoming && !loadingEvent"
                    :subtitles="subtitles"
                    :live="this.live"
                    :initialTime="initialTime || 0"
                    :showSubtitles="subtitleOn"
                    @error="clappr_error"
                    @init="clappr_init"
                    @bitrate="clappr_bitrate_change"
                />
                <CountdownClock
                    :key="keys+2"
                    v-if="upcoming && !loadingEvent"
                    :starttime="getStarttime"
                    @timesUp="timesUp"
                ></CountdownClock>
                <LoadingIndicator
                    v-if="loadingEvent"
                    font-size="40px"
                    color="white"
                ></LoadingIndicator>
            </div>
            <EventMeta
                :key="keys+3"
                :eventData="eventData"
                :sideBarOpen="sideBarOpen"
                :mobile="mobile"
                :var_view="var_view"
                :subtitleOn="subtitleOn"
                @toggle-side-bar="sideBarOpen = !sideBarOpen; onResize()"
                @toggle-cc="toggleCC"
                @skip="$refs.clappr.skip($event)"
                @change_rate="$refs.clappr.setPlaybackRate($event)"
                v-if="!player_only"
            ></EventMeta>
            <FooterBar v-if="!mobile"></FooterBar>
        </div>
        <EventStreamerBar
            :var_view="var_view"
            :mobile="mobile"
            :sideBarOpen="sideBarOpen"
            :customerData="customerData"
            :eventData="eventData"
            :key="keys+4"
            :subtitleOn="subtitleOn"
            v-if="!player_only"
            v-show="sideBarOpen || mobile"
            @event-opened="eventOpened"
            @toggle-cc="toggleCC"
            @go-to="goToTag($event)"
            @starttime="starttimeFound($event)"
            ref="sideBar"
            :tags="tags"
        ></EventStreamerBar>
    </div>
</template>

<script>
    import VueClappr from '../components/VueClappr.vue'
    import FooterBar from '../components/FooterBar.vue'
    import EventStreamerBar from '../components/EventStreamerBar.vue'
    import EventMeta from '../components/EventMeta.vue'
    import CountdownClock from '../components/CountdownClock.vue'
    import LoadingIndicator from '../components/LoadingIndicator.vue'
    // import PlaybackSpeedPlugin from '../deps/playBackSpeed.js'
    import SrvMixin from '../mixins/srvTimeMixin'
    import logger from '../mixins/logger'
    import fetchCustomerDataMixin from '../mixins/fetchCustomerDataMixin'
    import $ from 'jquery'
    import mux from 'mux-embed'

    export default {
        components: {
            VueClappr,
            EventStreamerBar,
            EventMeta,
            CountdownClock,
            LoadingIndicator,
            FooterBar
        },
        mixins: [SrvMixin, fetchCustomerDataMixin, logger],
        data() {
            return {
                source: '',
                customerData: undefined,
                eventData: undefined,
                sideBarOpen: false,
                mobile: false,
                keys: 0,
                record: false,
                upcoming: false,
                live: false,
                serverTime: undefined,
                loadingEvent: true,
                subtitleOn: false,
                togglerHover: false,
                hideArrow: false,
                server_time: undefined,
                record_source: undefined,
                initialTime: undefined,
                refreshInterval: undefined,
                tags: null,
                hard_close_listener: null,
                playerInitTimestamp: 0 // Tracks player start time for Mux.com analytics
            }
        },
        async created() {
            let isIe = this.$browserDetect.isIE
            if(isIe) {
                this.$router.push({
                    path: '/explorer'
                })
                return
            }

            if(this.$route.query.view == 'full_screen_chat') {
                this.$router.push({
                    path: `/c/${this.$route.params.id}/e/chat/${this.$route.params.eventid}`
                })
            }
            
            await this.fetchEventData()
            await this.getTags()

            this.$store.commit('event_opened')
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
                setTimeout(this.onResize, 10);
            })
        },
        methods: {
            clappr_init() {
                this.playerInitTimestamp = mux.utils.now();
            },
            clappr_error(err) {
                if(this.record) {
                    this.record_source = undefined;
                    this.record = false;
                    this.keys++;
                }

                // Send error to Mux.com analytics
                let errorCode = 100
                let errorMessage = 'Unknown error'
                let errorContext = {}
                
                try {
                  errorMessage = err.error_obj.code
                  errorCode = 101
                  if (err.error_obj.level == 'fatal') {
                    errorCode = 102
                  }
                } catch (_error) {
                  // Ignore unknown error structure
                }

                try {
                  errorContext = err.error_obj.description
                } catch (_error) {
                  // Ignore unknown error structure
                }

                let video = document.querySelector('video')
                mux.emit(video, 'error', {
                  player_error_code: errorCode,
                  player_error_message: errorMessage,
                  player_error_context: errorContext
                });
            },
            clappr_bitrate_change(bitrate) {
              // https://clappr.github.io/classes/Events.html#event_PLAYBACK_BITRATE
              // Send bitrate change to Mux.com analytics
              let video = document.querySelector('video')
              mux.emit(video, 'renditionchange', {
                video_source_bitrate: bitrate.bandwidth,
                video_source_width: bitrate.width,
                video_source_height: bitrate.height
              });
            },
            starttimeFound(tag) {
                // if(!this.eventData.meta.show_tags) return;
                this.initialTime = tag.stream_time;
                this.keys++;
            },
            toggleCC(newValue) {
                if (typeof(newValue) == "undefined") {
                  this.subtitleOn = !this.subtitleOn
                } else {
                  this.subtitleOn = newValue
                }

                this.$refs.clappr.toggleCC(this.subtitleOn)
            },
            async getTags() {
                this.initialTime = undefined;
                const url = `${this.$store.state.apiUrl}/tags/${this.$route.params.eventid}.json`
                fetch(url)
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        this.loading = false;
                        if(data) this.tags = data.tags.sort((a, b) => {return a.stream_time - b.stream_time});
                        this.checkStarttime()
                        this.tags = this.tags.filter(tag => { return tag.meta.type == 42 })
                    })
                    .catch(() => {
                        this.initialTime = undefined;
                        this.loading = false;
                    })
            },
            checkStarttime() {
                this.tags.find(tag => {
                    if(tag.meta.type == 46 && !this.$store.getters.starttimeSet) {
                        this.starttimeFound(tag)
                        this.$store.commit('setStarttime')
                        return ''
                    }
                })
            },
            setRefreshInterval() {
                if(this.refreshInterval) return
                this.refreshInterval = setInterval(() => {
                    this.getTags()
                }, 1000 * 60)
            },
            async fetchEventData() {
                const url = `${this.$store.state.apiUrl}/events/${this.$route.params.eventid}.json`
                this.$http
                    .get(url)
                    .then(async response => {

                        let date_header = response.headers.get('Date')
                        if(!date_header) this.server_time = await this.srvTime()
                        else this.server_time = new Date(date_header).getTime()

                        this.eventData = response.body;
                        this.$store.state.eventData = response.body;

                        console.log("Event data fetched", response.body)
                        
                        this.playerStartTimestamp = 0

                        this.putRightSource()

                        try {
                            if(response.body.meta.show_chat) this.sideBarOpen = true;
                        } catch {
                            this.sideBarOpen = false;
                        }

                        this.checkSideBar()
                        this.keys++;
                        this.muxAnalyticsTrack()
                    })
                    .catch(err => this.log(err))
                    .finally(() => { return })
            },
            putRightSource() {
                // alert(JSON.stringify(this.eventData.meta))

                // Display player at open before time of event with 1 minute extra time for playlist generation
                var start = Math.min(
                  this.eventData.start_time + 60,
                  this.eventData.kick_off_time
                )
                var end = this.eventData.end_time

                this.upcoming = false;
                this.live = false;
                this.record = false;

                var server = parseInt(this.server_time / 1000);

                try {
                    if(this.eventData.meta.record_url) this.record = true;
                } catch {
                    this.record = false;
                }

                if(start > server) this.upcoming = true;
                else if (start <= server && end > server) this.live = true;
                
                if(this.live) {
                    this.source = this.eventData.url.url + this.eventData.url.live;
                    this.record_source = undefined;
                } else {
                    this.record_source = this.eventData.meta.record_url;
                    this.source = this.eventData.url.url + this.eventData.url.vod;
                }

                try {
                    this.useDvrControls = this.eventData.meta.use_dvr;
                } catch {
                    this.useDvrControls = false;
                }

                this.loadingEvent = false
                this.keys++;
            },
            checkSideBar() {
                // alert(this.eventData.meta.sidebar_default)
                if(this.eventData.meta.sidebar_default == 'none') this.sideBarOpen = false;
                else this.sideBarOpen = true;
                if(this.eventData.meta.sidebar_default == 'tags' && this.eventData.meta.show_tags) this.$refs.sideBar.openThisTab(3)
                if(this.eventData.meta.sidebar_default == 'chat' && this.eventData.meta.show_chat) this.$refs.sideBar.openThisTab(1)
                if(this.var_view) {
                    this.$refs.sideBar.openThisTab(0)
                    this.sideBarOpen = true;
                }
            },
            onResize() {
                if(document.querySelector('#player')) {
                    var w = $(window).width();
                    var h = $(window).height();

                    var vaaka = w > h * 1.1;
                    if(!vaaka && !this.player_only && !this.var_view) {
                    //Pysty -- 16:9 suhde playeriin ja lopun täyttää chat joka vieressä
                        if(w > 900) {
                            //Laptop
                            this.mobile = false;
                            this.playerRatioFixed();
                        } else {
                            //Mobile -- 16:9 suhde playeriin ja lopun täyttää chat joka alla 
                            this.mobile = true;
                            this.playerRatioFixedMobile();
                        }
                    } else if (!this.player_only && !this.var_view){
                        //Vaaka
                        if(w > 900) {
                            //Laptop
                            this.mobile = false;
                            this.playerHeight(h - 200)
                        } else {
                            //Mobile
                            this.mobile = true;
                            this.playerHeight(h - 50)
                        }
                    } else if (this.player_only) {
                        this.playerHeight(h)
                        $('.event').css({'padding-top': '0px'})
                    } else {
                        this.playerHeight(h - 60)
                    }
                }
            },
            playerHeight(h) {
                document.querySelector('#player').style.height = h + 'px';
            },
            async eventOpened() {
                await this.fetchEventData()
                this.getTags()
                // this.keys++;
            },
            timesUp() {
                this.fetchEventData()
                this.keys++;
                // this.putRightSource()
            },
            playerRatioFixed() {
                if(this.sideBarOpen) this.playerHeight( ( ( $(window).width() - 400) / 16 ) * 9 )
                else this.playerHeight( ( ( $(window).width()) / 16 ) * 9 )
            },
            playerRatioFixedMobile() {
                this.playerHeight( ( ( $(window).width() ) / 16 ) * 9 )
            },
            getQueryParam(p) {
                return this.$route.query[p]
            },
            goToTag(time) {
                this.$refs.clappr.setCurrentTime(time)
            },
            muxAnalyticsTrack() {
              // Get daily rotating viewer id hash
              let dailyViewerId = this.generateDailyId(this.$store.getters.viewerId)

              // Set Mux development or production data store key
              let envKey = '78ic6i7n7ro86j08c1kas5s31' // Development
              if (process.env.NODE_ENV !== 'development') {
                envKey = '7hd5fnec73ljnmn5v3ault2n5' // Production
              }

              // Wait a bit, get video player element and start tracking
              setTimeout(() => {
                  let video = document.querySelector('video')

                  // Video element might not exist if event is not yet started
                  if (video != null) {
                      // Setup Mux.com analytics data
                      let videoType = 'on-demand'
                      if (this.live) { videoType = 'live' }

                      let scanLangs = [this.currentLang].concat(["fi", "en", "sv"])
                      let videoLang = scanLangs.find(lang => {
                        if (this.eventData.meta.language[lang]) {
                          return lang
                        }
                      })

                      let data = {
                          env_key: envKey,
                          
                          player_name: 'Vue-Clappr Player',
                          player_init_time: this.playerInitTimestamp,
                          viewer_user_id: dailyViewerId,

                          video_id: this.eventData.uuid,
                          video_title: this.eventData.meta.language[videoLang].name,
                          sub_property_id: this.customerData.name,
                          video_stream_type: videoType,
                          video_content_type: 'event'
                      }

                      mux.monitor(video, { debug: false, disableCookies: true, data: data })
                  }
              }, 300)
            },
            /*
             * Generate daily rotating hash to track unique viewers
             */
            generateDailyId(browserId) {
              // Generate daily changing part of the tracking id
              const staticHash = "yt3489yt98q4nfh349ht934ht9vhv98nqc98nqc48uc8f4uhahiouqht43q89h4843q8gq4chqncfh3yg89"
              const dailyHashCharacters = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890-".split("")
              const dailyHashCharactersLength = dailyHashCharacters.length

              // Derive big number from current date
              const today = new Date()
              const todayNumber = (
                today.getFullYear() * (1 + today.getMonth()) * today.getDate() * (1 + today.getDay())
              ).toString()

              // Get number pairs from big number, move cursor in character list and pick next character
              let dailyHash = ""
              let letterPosition = 0
              for (let idx = 0; idx < (todayNumber.length - 1); idx++) {
                let numberPair = parseInt(
                  todayNumber.charAt(idx) + todayNumber.charAt(idx + 1),
                  10
                )
                
                letterPosition = (letterPosition + numberPair) % dailyHashCharactersLength
                dailyHash += dailyHashCharacters[letterPosition]
              }

              let hashContent = dailyHash + staticHash + browserId
              let hashId = this.cyrb53hash(hashContent).toString()

              return hashId
            },
            /*
             * Hash string, https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
             */
            cyrb53hash(str, seed = 0) {
              let h1 = 0xdeadbeef ^ seed
              let h2 = 0x41c6ce57 ^ seed
              
              for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i)
                h1 = Math.imul(h1 ^ ch, 2654435761)
                h2 = Math.imul(h2 ^ ch, 1597334677)
              }
              
              h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
              h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
              
              return 4294967296 * (2097151 & h2) + (h1 >>> 0)
            },
        },
        computed: {
            bodyStyle() {
                const grids = this.sideBarOpen && !this.mobile && !this.player_only ? '1fr 350px' : '1fr'
                const display = this.mobile ? 'block': 'grid';
                return {
                    'grid-template-columns': grids,
                    'display': display,
                    height: '100vh',
                }
            },
            currentLang() {
                return this.$store.state.lang
            },
            languages() {
                return this.$store.state.eventLanguages
            },
            getStarttime() {
              // Get display time for countdown clock. Open before time of event with 1 minute extra time for playlist generation
                if(this.eventData) {
                  return Math.min(
                    this.eventData.start_time + 60,
                    this.eventData.kick_off_time
                  )
                }
                else return ''
            },
            var_view() {
                var var_view = this.getQueryParam('view')

                // Use default view value of customer if no URL parameter is present
                if (var_view === "" || var_view === null || var_view === undefined) {
                    if (typeof this.customerData !== 'undefined' && typeof this.customerData.meta.view !== 'undefined') {
                        var_view = this.customerData.meta.view
                    }
                } 

                return var_view == 'var';
            },
            player_only() {
                var player_only = this.getQueryParam('player_only')
                return player_only == 'true';
            },
            player_style() {
                let soFar = {};
                let poster_url;
                try {
                    poster_url = this.customerData.meta.default_counter_poster;
                    poster_url = this.eventData.meta.counter_poster ? this.eventData.meta.counter_poster.file : poster_url;
                } catch {
                    poster_url = undefined;
                }

                if(this.upcoming && poster_url) {
                    soFar = {
                        'background-image': `url(${poster_url})`,
                        'background-color': '#dddddd',
                        'background-position': 'center center',
                        'background-repeat': 'no-repeat',
                        'background-size': 'cover',
                        'position': 'relative'
                    }
                }

                if(this.getQueryParam('player_only') == 'true') {
                    soFar['height'] = '100vh';
                }

                if(this.getQueryParam('view') == 'var') {
                    soFar['height'] = '95vh';
                }

                return soFar;
            },
            hlsOptions() {
                // Setup configuration object for HLS.js library used by Clappr player. Empty object uses HLS.js defaults.
                let hlsConfig = {}

                try {
                    // Add low latency configuration for HLS playback if requested
                    if(this.eventData.meta.use_low_latency_player) {
                        hlsConfig.liveSyncDuration = 2;
                    }
                } catch {
                    // Do nothing, HLS will use playlist EXT-X-TARGETDURATION * 3 as default live buffering/delay size
                }

                return hlsConfig
            },
            subtitles() {
                return this.eventData.meta.subtitle_url
            },
            options() {
                return {
                    width: '100%',
                    height: '100%',
                    poster: '',
                    mute: false,
                    autoplay: true,
                    loop: false,
                    parentId: '#player',
                    disable_context_menu: false,
                    disable_keyboard_shortcuts: false,
                    useDvrControls: false,
                    mediacontrol: {},
                    hideMediaControl: !this.var_view,
                    watermark: {
                        image: '',
                        url: '',
                        position: 'top-right'
                    },
                    playback: {
                        playInline: true,
                        'crossOrigin': 'anonymous',
                        externalTracks: [{lang: 'fi', label: 'Enabled', src: this.eventData.meta.subtitle_url, kind: 'subtitles'}],
                        hlsjsConfig: this.hlsOptions
                    }
                }
            }
        },
        beforeDestroy() {
            this.$store.state.eventData = {};
        }
    }
</script>

<style scoped lang="scss">
    #player {
        background-color: #333;
        position: relative;
    }

    .no_pad_event {
        padding-top: 0px;
    }

    .pad_event {
        padding-top: 50px;
    }

    .event {
        display: grid;
        width: 100%;
        height: 100vh !important;
        grid-template-columns: 1fr 350px;
    }

    .liha {
        -ms-overflow-style: none;
        grid-column: 1 / 2;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .sideBar {
        // grid-column: 2 / 3;
    }

    .toggler {
        position: absolute;
        // background-color: white;
        // border: 1px solid white;
        // height: 40px;
        // width: 36px;
        right: 3px;
        top: 3px;
        z-index: 1;
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        cursor: pointer;
        font-size: 25px;
        padding: 5px;
        transition-duration: 0.3s;
        border-radius: 4px;

        &:hover {
            // .togglerText {
            //     display: block;
            // }
            background-color: rgba(100, 100, 100, 0.4);
        }

        .togglerText {
            // display: none;
            font-size: 14px;
            padding-top: 2px;
            color: white;
            margin-right: 5px;
            // opacity: 0.7;
        }
    }

/* always present */
.expand-transition {
  transition: all .3s ease;
  width: 50px;
  padding: 10px;
  background-color: #eee;
  overflow: hidden;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
  width: 0px;
  padding: 0 10px;
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-leave {
    opacity: 0.7;
}

</style>
