<template>
  <div>
      <EventStreamerChat
        :fs="true"
        :key="chatKey"
        @refreshChat="refreshChat()"
    ></EventStreamerChat>
  </div>
</template>

<script>
import EventStreamerChat from '../components/EventStreamerChat.vue'
export default {
  components: { EventStreamerChat },
  data() {
    return {
      chatKey: 0
    }
  }
}
</script>
